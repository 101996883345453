import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { navigate } from "gatsby"

import device from "./device"

const FieldWrapper = styled.div`
  margin-bottom: 3rem;
  position: relative;
  ${device.small`width: 100%;`}
  & > * {
    display: block;
  }
  label {
    color: var(--white);
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .input {
    width: 100%;
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid rgb(119, 119, 119, 0.3);
    background: var(--white);
    color: var(--black);
  }
  .error {
    position: absolute;
    bottom: 0.5rem;
    right: 5px;
    color: crimson;
    font-size: 0.7rem;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  button {
    background: var(--darkgrey);
    color: var(--white);
    border: 1px solid var(--white);
    font-size: 0.7rem;
    border: 0;
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    padding: calc(0.5rem - 1px) 1.5rem;
  }
`

const SubmitError = styled.div`
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  bottom: 1rem;
  left: 0;
  width: 100%;
  color: crimson;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Schema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
})

const ContactForm = () => {
  const [error, setError] = useState(false)
  function handleSubmit(values, setSubmitting, resetForm) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...values,
      }),
    })
      .then(() => {
        navigate("/form-submitted/", {
          state: { submitted: true },
        })
      })
      .catch(() => {
        resetForm()
        setSubmitting(false)
        setError(true)
      })
  }

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        msg: "",
      }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm)
      }}
    >
      {({ isSubmitting }) => (
        <Form
          method="post"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <FieldWrapper>
            <label htmlFor="name">Name *</label>
            <Field type="text" name="name" className="input" required/>
            <div className="error">
              <ErrorMessage name="name" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="email">Email *</label>
            <Field type="email" name="email" className="input"  required/>
            <div className="error">
              <ErrorMessage name="email" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="name">Message</label>
            <Field name="msg" component="textarea" rows="5" className="input" />
          </FieldWrapper>

          <ButtonWrapper>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </ButtonWrapper>
          {error && (
            <SubmitError>Something went wrong. Please try again!</SubmitError>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
